<template>
<div class="p-grid">
    <div class="home">
        <div class="features" style="text-align: center;background-image: url('./images/prefondo4.png');">
            <h3 style="color:#ffcc2a;">{{$t('Cotización de Pólizas')}}</h3>
            <div class="p-grid" >

                <div class="p-col-12 p-md-12">
                    <div class="feature-card">
                        <DataTable :value="poliza_vehiculo" stripedRows responsiveLayout="scroll">
                            <template #header>
                                {{$t('Vehicle Insurance')}}
                            </template>
                            <Column :header="$t('Estatus')" headerStyle="width: 200px">
                                <template #body="slotProps">
                                    <div v-if="i18n.locale() == 'es'">{{slotProps.data.evaluacion.tipopoliza_nombre}}</div>
                                    <div v-else>{{slotProps.data.evaluacion.tipopoliza_name}}</div>
									<Avatar icon="bi bi-shield-shaded" class="p-mr-2" size="large" style="background-color:gray; color: #ffffff" v-if="slotProps.data.estado==7"/>
									<div v-if="slotProps.data.estado==7">
										{{$t('Proceso Renovación')}}
									</div>
                                    <Knob v-model="slotProps.data.estado" :min="0" :max="3" :valueTemplate="(parseFloat(slotProps.data.estado)*100/3).toFixed(0)+'%'"  readonly="false" v-if="slotProps.data.estado<=3"/>
                                    {{slotProps.data.fecha}}                              
                                </template>
                            </Column> 
                            <Column field="id" :header="$t('Nº')" headerStyle="width: 70px">
                                <template #body="slotProps">
                                    {{slotProps.data.id}}                              
                                </template>
                            </Column>
							<Column :header="$t('Detalles')">
                                <template #body="slotProps">
                                    <Button style="width: 110px;" type="button" :label="$t('Vehículo')" @click="Ver1(1,slotProps.data)" class="p-button-success" icon="pi pi-eye" iconPos="right"/>
                                    <br><br>
                                    <Button style="width: 110px;" type="button" :label="$t('Licencia')" @click="Ver1(2,slotProps.data.licencias)" class="p-button-success" icon="pi pi-eye" iconPos="right"/>
                                    <div v-if="slotProps.data.evaluacion.comentario && slotProps.data.evaluacion.comentario != ''">
                                        <br><Button style="width: 120px;" type="button" :label="$t('Observación')" @click="VerMENS(slotProps.data.evaluacion.comentario)" class="p-button-info" icon="pi pi-eye" iconPos="right"/>
                                    </div>
                                </template>
                            </Column>
                            <Column :header="$t('Nombre Completo')" field="nombre">
                                <template #body="slotProps">
									<div>
										{{slotProps.data.nombre}}
									</div>
                                </template>
                            </Column>
                            <Column field="cuotas" :header="$t('Monto Cuota')">
                                <template #body="slotProps">
									{{moneda(((slotProps.data.evaluacion.primabrutat*duracion[slotProps.data.duracion].valor*(1+cuotas[slotProps.data.cuotas].porcentaje/100)+parseFloat(slotProps.data.evaluacion.kosten)+parseFloat(slotProps.data.costo2))*(1+parseFloat(slotProps.data.impuesto)/100))/cuotas[slotProps.data.cuotas].valor)}}
                                </template>
                            </Column>
                            <Column :header="$t('Monto Total')">
                                <template #body="slotProps">
                                    {{moneda((slotProps.data.evaluacion.primabrutat*duracion[slotProps.data.duracion].valor*(1+cuotas[slotProps.data.cuotas].porcentaje/100)+parseFloat(slotProps.data.evaluacion.kosten)+parseFloat(slotProps.data.costo2))*(1+parseFloat(slotProps.data.impuesto)/100))}}
                                    <br><br>
                                    <Button icon="pi pi-file-pdf" class="p-button-rounded p-button-primary p-mr-2" @click="Ver(slotProps.data)"/>
                                    <Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="EditPoliza(slotProps.data)" v-if="edit"/>
                                </template>
                            </Column>
							
                        </DataTable>
                    </div>
                </div>

          </div>
        </div>
	</div>

	<Dialog v-model:visible="Dialog" :style="{width: size}" :header="$t('Vehicle Insurance')" :modal="true" :maximizable="true">
		<br>
        <div class="confirmation-content">
            <div class="p-field">
                <h4>{{$t('Información Básica')}}</h4>
				<div class="p-grid">
					<div class="p-col-3">
						{{$t('Fecha')}}: <strong>{{poliza.fecha}}</strong>			
					</div>
					<div class="p-col-3">
						{{$t('Número ID')}}: <strong>{{poliza.code}}</strong>			
					</div>
					<div class="p-col-3" v-if="i18n.locale() == 'es' && poliza.tipocliente==1">
						{{$t('Sexo')}}: <strong>{{poliza.sexnombre}}</strong> 			
					</div>
					<div class="p-col-3" v-if="i18n.locale() == 'en' && poliza.tipocliente==1">
						{{$t('Sexo')}}: <strong>{{poliza.sexname}}</strong> 			
					</div>
					<div class="p-col-6">
						{{$t('Nombre Completo')}}: <strong>{{poliza.nombre}}</strong>			
					</div>
					<div class="p-col-6">
						{{$t('Correo')}}: <strong>{{poliza.correo}}</strong>			
					</div>
					<div class="p-col-4" v-if="poliza.tipocliente==1">
						{{$t('Fecha de nacimiento')}}: <strong>{{poliza.fechanac}}</strong>			
					</div>
					<div class="p-col-2" v-if="poliza.tipocliente==1">
						{{$t('Edad')}}: <strong>{{poliza.edad}}</strong>			
					</div>
					<div class="p-col-6" v-if="i18n.locale() == 'es'">
						{{$t('Nacionalidad')}}: <strong>{{poliza.nacionalidad}}</strong> 			
					</div>
					<div class="p-col-6" v-else>
						{{$t('Nacionalidad')}}: <strong>{{poliza.nationality}}</strong> 			
					</div>
					<div class="p-col-4">
						{{$t('Localidad')}}: <strong>{{poliza.localidadn}}</strong>			
					</div>
					<div class="p-col-4">
						{{$t('Teléfono móvil')}}: <strong>{{poliza.celular}}</strong>			
					</div>
					<div class="p-col-4">
						{{$t('Teléfono privado')}}: <strong>{{poliza.tlflocal}}</strong>			
					</div>
				</div>
            </div>
            <br>
            <div class="p-field">
                <h4>{{$t('Información del Vehículo')}}</h4>
				<div class="p-grid">
					<div class="p-col-12" v-if="i18n.locale() == 'es'">
						{{$t('Tipo de Vehículo')}}: <strong>{{poliza.tipovehnombre}}</strong> 			
					</div>
					<div class="p-col-12" v-else>
						{{$t('Tipo de Vehículo')}}: <strong>{{poliza.tipovehname}}</strong> 			
					</div>
					<div class="p-col-4">
						{{$t('Marca')}}: <strong>{{poliza.marca}}</strong>			
					</div>
					<div class="p-col-4">
						{{$t('Módelo')}}: <strong>{{poliza.modelo}}</strong>			
					</div>
					<div class="p-col-4">
						<label for="state">{{$t('Año de construcción')}} ({{poliza.edadveh}})</label><br>
						<Dropdown v-model="poliza.veh_ano" :options="years" optionLabel="nombre" placeholder="...?" @change="Actualizacion1()"></Dropdown>		
					</div>
					<div class="p-col-4">
						{{$t('Año de Vehículo')}}: <strong>{{poliza.veh_compra}}</strong>			
					</div>
					<div class="p-col-4">
						{{$t('Automático')}} <i class="pi pi-check" v-if="poliza.veh_automatico"></i> <i class="pi pi-times" v-else></i>			
					</div>
					<div class="p-col-4">
						{{$t('Número de chasis')}}: <strong>{{poliza.veh_chasis}}</strong>
					</div>
					<div class="p-col-4">
						{{$t('Nº placa')}}: <strong>{{poliza.veh_placa}}</strong>
					</div>
					<div class="p-col-4">
						{{$t('Nº asientos')}}: <strong>{{poliza.veh_asientos}}</strong>
					</div>
					<div class="p-col-4">
						{{$t('Color')}}: <strong :style="'background: #'+poliza.veh_color+';color:#'+poliza.veh_color+';'">{{poliza.veh_color}}</strong>
					</div>
					<div class="p-col-4">
						{{$t('Posición del Volante')}}: <strong>{{poliza.veh_volante}}</strong>
					</div>
				</div>
            </div>
            <br>
            <div class="p-field">
                <h4>Keuringskaart</h4>
				<div class="p-grid">
					<div class="p-col-4">
						{{$t('Registro')}}:<strong>{{poliza.cardreg}}</strong>
					</div>
					<div class="p-col-4">
						{{$t('Fecha de emisión')}}:<strong>{{poliza.cardemi}}</strong>
					</div>
					<div class="p-col-4">
						<img style="width: 100px;" :src="url.upload+poliza.veh_adverso" @click="toggle" v-if="poliza.veh_adverso"/>
						<FileUpload :chooseLabel="$t('Subir')" uploadLabel="Cargar" mode="basic" :name="codimg" :url="url.api+'upload.php'"  accept="image/jpeg,image/jpg" :maxFileSize="4000000" @upload="myUploader(2,poliza)" :auto="true" v-if="poliza.veh_adverso"/>
						<OverlayPanel ref="op" appendTo="body" :showCloseIcon="true">
							<img style="width: 700px;" :src="url.upload+poliza.veh_adverso"/>
						</OverlayPanel>
					</div>
				</div>
            </div>
            <br>
            <div class="p-field">
                <h4>{{$t('Información de la Licencia')}}</h4>
				<div class="p-grid">
					<div class="p-col-12">
						<DataTable :value="poliza.licencias" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
								:rows="5" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
							<Column :header="$t('Imagen')">
								<template #body="slotProps">
									<img style="width: 50px;" :src="url.upload+slotProps.data.adverso" @click="IMG(url.upload+slotProps.data.adverso)" v-if="slotProps.data.adverso"/>
									<FileUpload :chooseLabel="$t('Subir')" uploadLabel="Cargar" mode="basic" :name="codimg" :url="url.api+'upload.php'"  accept="image/jpeg,image/jpg" :maxFileSize="4000000" @upload="myUploader(1,slotProps.data)" :auto="true" v-if="slotProps.data.adverso"/>
								</template>
							</Column>
							<Column :header="$t('Licencia')">
								<template #body="slotProps">
									{{slotProps.data.categoria}}
								</template>
							</Column>
							<Column :header="$t('Número ID')">
								<template #body="slotProps">
									{{slotProps.data.code}}
									<Avatar icon="bi bi-asterisk" class="p-mr-2" style="background-color:blue; color: #ffffff" v-if="slotProps.data.habitual"/>
								</template>
							</Column>
							<Column :header="$t('Nombre Completo')">
								<template #body="slotProps">
									{{slotProps.data.nombre}}
								</template>
							</Column>
							<Column :header="$t('Fecha de emisión')">
								<template #body="slotProps">
									{{convfech(slotProps.data.emision)}}
								</template>
							</Column>
							<Column :header="$t('Fecha de caducidad')">
								<template #body="slotProps">
									{{convfech(slotProps.data.caducidad)}}
								</template>
							</Column>
							<Column :header="$t('País de emisión')">
								<template #body="slotProps" v-if="i18n.locale() == 'es'">
									{{slotProps.data.pais_nombre}}
								</template>
								<template #body="slotProps" v-else>
									{{slotProps.data.pais_name}}
								</template>
							</Column>
						</DataTable>
						<OverlayPanel ref="op1" appendTo="body" :showCloseIcon="true">
							<img style="width: 350px;" :src="imagen"/>
						</OverlayPanel>
					</div>
				</div>
            </div>
            <br>
            <div class="p-field">
                <h4>{{$t('Información Adicional')}}</h4>
				<div class="p-grid">
					<div class="p-col-12">
						{{$t('¿Tiene alguna discapacidad / enfermedad física o de otro tipo?')}}: <strong v-if="poliza.preguntas.pregunta1">Yes</strong><strong v-else>NO</strong>
					</div>
					<div class="p-col-12">
						{{$t('¿Has sido condenada penalmente?')}}: <strong v-if="poliza.preguntas.pregunta2">Yes</strong><strong v-else>NO</strong>
					</div>
					<div class="p-col-12">
						{{$t('¿Alguna vez le han negado el seguro?')}}: <strong v-if="poliza.preguntas.pregunta3">Yes</strong><strong v-else>NO</strong>
					</div>
					<div class="p-col-12">
						{{$t('¿Existen circunstancias circundantes que sean importantes para aceptar este seguro?')}}: <strong v-if="poliza.preguntas.pregunta4">Yes</strong><strong v-else>NO</strong>
					</div>
					<div class="p-col-12">
						{{$t('¿O se imponen condiciones agravadas?')}}: <strong v-if="poliza.preguntas.pregunta5">Yes</strong><strong v-else>NO</strong>
					</div>
					<div class="p-col-12">
						{{$t('¿Descuento por no siniestralidad %?')}}: <strong v-if="poliza.preguntas.noclain">Yes</strong><strong v-else>NO</strong>
					</div>
					<div class="p-col-4" v-if="poliza.preguntas.noclain">
						<img style="width: 100px;" :src="url.upload+poliza.preguntas.document" @click="toggle2" />
						<OverlayPanel ref="op2" appendTo="body" :showCloseIcon="true">
							<img style="width: 700px;" :src="url.upload+poliza.preguntas.document"/>
						</OverlayPanel>
					</div>
				</div>
            </div>
            <br>
            <div class="p-field">
                <h4>{{$t('Evaluación')}}</h4>
                <h5 v-if="i18n.locale() == 'es'">{{$t('Tipo de Póliza')}}: <strong>{{poliza.evaluacion.tipopoliza_name}}</strong></h5>
                <h5 v-else>{{$t('Tipo de Póliza')}}: <strong>{{poliza.evaluacion.tipopoliza_name}}</strong></h5>     
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-col-12">
                        <Checkbox v-model="poliza.exoneracion" :binary="true"/> <strong>{{$t('Exoneracion')}}</strong><br><br>
                    </div>
					<div class="p-col-3">
						<label for="state">{{$t('Tarifa')}}</label>
						<Dropdown v-model="poliza.evaluacion.poliza_valor" :options="poliza_valores" optionLabel="nombre" placeholder="Seleccione..." @change="Actualizacion2()" v-if="i18n.locale() == 'es'"></Dropdown>
						<Dropdown v-model="poliza.evaluacion.poliza_valor" :options="poliza_valores" optionLabel="name" placeholder="Select..." @change="Actualizacion2()" v-if="i18n.locale() == 'en'"></Dropdown>
					</div>
					<div class="p-col-3">
						<label>{{$t('Costo')}}</label>
						<InputText v-model="this.poliza.evaluacion.kosten" @change="Calculo()" type="number" min="0"/>
					</div>
					<div class="p-col-3">
						<label for="state">{{$t('Sin reclamación')}}</label>
						<Dropdown v-model="poliza.evaluacion.noclaim" :options="noclaims" optionLabel="nombre" placeholder="Select..." @change="Calculo()"></Dropdown>
					</div>	
					<div class="p-col-3">
						<label for="state">{{$t('Descuento Especial')}}</label>
						<Dropdown v-model="poliza.evaluacion.descuento" :options="descuentos" optionLabel="nombre" placeholder="Select..." @change="Calculo()"></Dropdown>
					</div>	
					<div class="p-col-3">
						<label for="state">{{$t('Duración')}}</label>
						<Dropdown v-model="poliza.duracion" :options="duracion" :optionLabel="$t('nombre')" placeholder="Select..." @change="Calculo()"></Dropdown>
					</div>	
					<div class="p-col-3" v-if="poliza.duracion">
						<label for="state">{{$t('Pagos')}}</label>
						<Dropdown v-model="poliza.cuotas" :options="cuotas" :optionLabel="$t('nombre')" placeholder="Select..." @change="Calculo()" v-if="poliza.duracion.code==0"></Dropdown>
						<Dropdown v-model="poliza.cuotas" :options="cuotas2" :optionLabel="$t('nombre')" placeholder="Select..." @change="Calculo()" v-if="poliza.duracion.code==1"></Dropdown>
						<Dropdown v-model="poliza.cuotas" :options="cuotas1" :optionLabel="$t('nombre')" placeholder="Select..." @change="Calculo()" v-if="poliza.duracion.code==2"></Dropdown>
					</div>	
					<div class="p-col-12">
						<label>{{$t('Observación')}}</label>
						<Textarea v-model="poliza.evaluacion.comentario" rows="2"/>
					</div>
				</div>
            </div>
            <div class="p-field">
				<h4>{{$t('Cálculo Anual')}}</h4>
                <div class="p-fluid p-formgrid p-grid">
					<div class="p-col-6">
						{{$t('Valor de Vehículo')}}: {{moneda(poliza.evaluacion.valorcatalogo)}}
					</div>
					<!--
					<div class="p-col-3">{{$t('Cobertura')}}</div>
					<div class="p-col-3">{{moneda(poliza.evaluacion.limite)}}</div>
					-->
					<div class="p-col-6"></div>
					<div class="p-col-6">
						{{$t('Responsabilidad')}}: {{moneda(poliza.responsabilidad)}}	
					</div>
					<div class="p-col-3">{{$t('Prima Bruta WA')}}</div>
					<div class="p-col-3" v-if="poliza.evaluacion.tipopoliza.casco">
						{{moneda(parseFloat(poliza.evaluacion.primabruta) + parseFloat(poliza.evaluacion.valorcatalogo*poliza.evaluacion.limite))}}
					</div>
					<div class="p-col-3" v-else>
						{{moneda(parseFloat(poliza.evaluacion.primabruta*poliza.evaluacion.limite))}}
					</div>
					<div class="p-col-6"></div>					
					<div class="p-col-3">{{$t('Recargo Años del Vehículo')}}</div>
					<div class="p-col-3">{{moneda(recarga_edadveh)}}</div>
					<div class="p-col-6"></div>
					<div class="p-col-3">{{$t('Recargo Edad Persona')}}</div>
					<div class="p-col-3">{{moneda(recarga_edad)}}</div>
					<div class="p-col-6"></div>
					<div class="p-col-3">{{$t('Recargo Volante')}}</div>
					<div class="p-col-3">{{moneda(recarga_volante)}}</div>
					<br><br>
					<div class="p-col-6"></div>
					<div class="p-col-3"><strong>{{$t('Total')}} WA</strong></div>
					<div class="p-col-3"><strong>{{moneda(premie)}}</strong></div>
					<div class="p-col-6"></div>
					<div class="p-col-3">{{$t('Sin reclamación')}} WA</div>
					<div class="p-col-3">{{moneda(premie*poliza.evaluacion.noclaim.valor)}}</div>
					<div class="p-col-6"></div>
					<div class="p-col-3">{{$t('Cobertura Adicional')}}</div>
					<div class="p-col-3">{{moneda(poliza.evaluacion.adic_asientos)}}</div>
					<div class="p-col-6"></div>
					<div class="p-col-3">{{$t('Forma de pago')}}</div>
					<div class="p-col-3">{{moneda((premie*(1-poliza.evaluacion.noclaim.valor)+parseFloat(poliza.evaluacion.adic_asientos))*parseFloat(poliza.cuotas.porcentaje/100))}} ({{poliza.cuotas.porcentaje}}%)</div>
					<div class="p-col-6"></div>
					<div class="p-col-3">{{$t('Descuento Especial')}} WA</div>
					<div class="p-col-3">{{moneda(premie*poliza.evaluacion.descuento.valor)}}</div>
				</div>
            </div>
            <div class="p-field">
                <h4>{{$t('Resumen de Póliza')}}</h4>
                <div class="p-grid">
					<div class="p-col-3">
						{{$t('Duración')}}: 
						<strong v-if="i18n.locale() == 'es'">{{poliza.duracion.nombre}}</strong>
						<strong v-else>{{poliza.duracion.name}}</strong> 
					</div>
					<div class="p-col-3">
						{{$t('Cuotas')}}: <strong>{{poliza.cuotas.valor}}</strong> 
					</div>

					<div class="p-col-3"><strong>{{$t('Premie WA')}}</strong></div>
					<div class="p-col-3">{{moneda((parseFloat(premie*(1-poliza.evaluacion.noclaim.valor-poliza.evaluacion.descuento.valor))+parseFloat(poliza.evaluacion.adic_asientos))*(1+poliza.cuotas.porcentaje/100)*poliza.duracion.valor)}}</div>
					
					<div class="p-col-6"></div>
					<div class="p-col-3"><strong>{{$t('Costo')}}</strong></div>
					<div class="p-col-3"><strong>{{moneda(poliza.evaluacion.kosten)}}</strong></div>

					<div class="p-col-6"></div>
					<div class="p-col-3"><strong>{{$t('OB')}}</strong></div>
					<div class="p-col-3"><strong>{{moneda(ob)}}</strong></div>

					<div class="p-col-6"></div>
					<div class="p-col-3"><h4><strong>{{$t('Monto')}}</strong></h4></div>
					<div class="p-col-3"><h4><strong>{{moneda(((parseFloat(premie*(1-poliza.evaluacion.noclaim.valor-poliza.evaluacion.descuento.valor)+parseFloat(poliza.evaluacion.adic_asientos))*(1+poliza.cuotas.porcentaje/100)*poliza.duracion.valor)+parseFloat(this.poliza.evaluacion.kosten)+parseFloat(crs)+parseFloat(ob)).toFixed(2))}}</strong></h4></div>
				</div>
            </div>
		</div>
		<template #footer>
			{{$t('Estatus')}}: 
			<Dropdown v-model="poliza.estado" :options="estados" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')" v-if="poliza.estado!=7" ></Dropdown>
			<InputSwitch v-model="procesar" v-if="poliza.estado==7"/>
			<Button :label="$t('Guardar')" icon="pi pi-check" class="p-button-secondary" @click="save" />
			<Button :label="$t('Cancelar')" icon="pi pi-times" class="p-button-danger" @click="Dialog = false"/>
		</template>
	</Dialog>

	<Dialog v-model:visible="Diag" header=" " :style="{width: size}" :modal="true">
        <DataTable :value="[ver_mostrar]" stripedRows responsiveLayout="scroll" v-if="Caso == 1">
            <Column :header="$t('Marca')">
                <template #body="slotProps">
                    {{slotProps.data.marca}}
                </template>
            </Column>
            <Column :header="$t('Módelo')">
                <template #body="slotProps">
                    {{slotProps.data.modelo}}
                </template>
            </Column>
            <Column :header="$t('Nombre Completo')">
                <template #body="slotProps">
                    {{slotProps.data.nombre}}
                </template>
            </Column>
            <Column :header="$t('Certificado')">
                <template #body="slotProps">
					<img style="width: 100px;" :src="url.upload+slotProps.data.veh_adverso"/>
                </template>
            </Column>
        </DataTable>

        <DataTable :value="ver_mostrar" stripedRows responsiveLayout="scroll" v-if="Caso == 2">
            <Column :header="$t('Licencia')">
				<template #body="slotProps">
					<!--<img style="width: 100px;" :src="'images/' + slotProps.data.categoria + '.png'" />-->
					{{slotProps.data.categoria}}
				</template>
			</Column>
            <Column :header="$t('Nombre Completo')">
                <template #body="slotProps">
                    {{slotProps.data.nombre}}
                </template>
            </Column>
            <Column :header="$t('Número ID')">
                <template #body="slotProps">
                    {{slotProps.data.code}}
					<Avatar icon="bi bi-asterisk" class="p-mr-2" style="background-color:blue; color: #ffffff" v-if="slotProps.data.habitual"/>
                </template>
            </Column>
            <Column :header="$t('Anverso')">
                <template #body="slotProps">
                    <img style="width: 100px;" :src="url.upload+slotProps.data.adverso"/>
                </template>
            </Column>
        </DataTable>

	</Dialog>

	<Dialog v-model:visible="DiagMENS" header=" " :style="{width: size}" :modal="true">
        <br>
        {{mensaje}}
	</Dialog>




</div>
</template>

<script>
import API from "../service/ApiConsulta";
import { useI18nPlugin } from '@unify/vuex-i18n';
import url from "@/service/_URL";
import PDF from "../service/PDF";
import Token from "uuid-token-generator";

export default {
	components: {
    },
	data() {
		return {
			insert: false,
			edit: false,
			del: false,
			i18n: null,
            caso: null,
            Dialog: false,
            poliza_vehiculo: [],
            poliza: null,
			size: '60vw',
			url: null,
			estados: [],
			noclaims: [],
			descuentos: [],
            duracion: [],
            cuotas: [],
            cuotas1: [],
            cuotas2: [],
            costo: null,
            years: [],
			poliza_tipos: [],
            poliza_valores: [],
            factor_limite: 0,
            recarga_edadveh: 0,
            recarga_edad: 0,
            recarga_volante: 0,
			premie: 0,
			kosten: 0,
			crs: 0,
			ob: 0,
			impuesto: 0,
			ver_mostrar: null,
			Diag: false,
			Caso: 0,
			imagen: null,
			DiagMENS: false,
			mensaje: null,
			procesar: false,
			renovacion: false,
			codimg: null,
			ver: null
		}
	},
	productService: null,
	eventService: null,
	created() {
		this.url = url;
		this.i18n = useI18nPlugin();
		this.$store.commit('Validar',this.$route);
		if (this.$store.state.mobile){this.size = '90vw';}
        this.Mostrar();		

	},
	mounted() {
	},
	methods: {
        Mostrar(){
			this.$store.commit('Loading');
			this.estados = [];
			this.poliza = null;
			this.poliza_vehiculo = [];
            this.Dialogo = false;
			this.duracion = [];
			this.cuotas = [];
			this.cuotas1 = [];
            this.cuotas2 = [];
            const Consulta = new API('Evaluacion');
            Consulta.Ini('Vehiculo').then(result => {
                //this.$store.state.error = result;
				this.insert = result.insert;
				this.edit = result.edit;
				this.del = result.delete;
				if(result.consult.poliza_vehiculo){
					this.poliza_vehiculo = result.consult.poliza_vehiculo;
				}				
				this.estados = result.consult.estados;
				this.noclaims = result.consult.noclaims;
				this.descuentos = result.consult.descuentos;
                this.duracion = result.consult.duracion;
                this.cuotas = result.consult.cuotas;
				this.cuotas.forEach(element => {
					if(element.code==0){
						this.cuotas1.push(element);
						this.cuotas2.push(element);
					}
					if(element.code==1){
						this.cuotas2.push(element);
					}
				});
                //this.costos = result.consult.costos;
                this.kosten = result.consult.valores.costo1;
				this.crs = result.consult.valores.costo2;
				this.impuesto = result.consult.valores.impuesto;
                this.$store.commit('Loading');
            }); 
        },
		Ver1(caso,data){
			this.Caso = caso;
			this.ver_mostrar = data;
			this.Diag = true;
		},
		VerMENS(data){
			this.mensaje = data;
			this.DiagMENS = true;
		},
		myUploader(opcion,data) {
			this.$store.commit('Loading');
			const Consulta = new API('Evaluacion');
			Consulta.Procesar('Vehiculo',{
				caso: 	'UPDIMG',
				foto: data,
				opcion: opcion,
				nuevo: this.codimg
			}).then(response => {
                //this.$store.state.error = response;	
				this.ver = response;
				if(response.result){
					if(opcion==1){
						data.adverso = response.result;
					}
					if(opcion==2){
						this.poliza.veh_adverso = response.result;
					}
				} else {
					this.$toast.add({
						severity:'warn', 
						summary: this.$t('Advertencia'), 
						detail: this.$t('Error en Foto'), 
						life: 3000
					});
				}
				const tokgen = new Token(256);
				this.codimg = tokgen.generate();
				this.$store.commit('Loading');
			});
		},
		EditPoliza(poliza) {
			this.$store.commit('Loading');
			this.procesar = false;
			const tokgen = new Token(256);
			this.codimg = tokgen.generate();
			this.poliza = null;
            this.poliza_tipos = [];
			this.years = [];
			this.poliza = {...poliza};
            const Consulta = new API('Evaluacion');
			Consulta.Procesar('Vehiculo',{
				caso: 	'Ver',
				id: poliza.vehano,
			}).then(response => {
                //this.$store.state.error = response;
                this.poliza_tipos = response.result.poliza_tipos;
                this.poliza_valores = response.result.poliza_valores;
				this.years = response.result.years;
                this.Dialog = true;
                this.poliza_tipos.forEach(element => {
                    if(this.poliza.evaluacion.tipopoliza==element.code){
						this.poliza.evaluacion.tipopoliza = element;
                    }
                });  
                this.poliza_valores.forEach(element => {
                    if(this.poliza.evaluacion.poliza_valor==element.code){
						this.poliza.evaluacion.poliza_valor = element;
                    }
                });
                this.estados.forEach(element => {
                    if(this.poliza.estado==element.code){
                        this.poliza.estado = element;
                    }
                });  
				if(poliza.estado==7){
					this.renovacion = true;
				} else {this.renovacion = false;}
                this.noclaims.forEach(element => {
                    if(this.poliza.evaluacion.noclaim_id==element.code){
						this.poliza.evaluacion.noclaim = element;
                    }
                });  
                this.descuentos.forEach(element => {
                    if(this.poliza.evaluacion.descuento==element.code){
						this.poliza.evaluacion.descuento = element;
                    }
                }); 
				/* 
                this.costos.forEach(element => {
                    if(this.poliza.evaluacion.kosten==element.valor){
						this.costo = element;
                    }
                });
				*/
                this.years.forEach(element => {
                    if(this.poliza.vehano==element.code){
						this.poliza.veh_ano = element;
                    }
                });
				this.duracion.forEach(element => {
					if(this.poliza.duracion==element.code){
						this.poliza.duracion = element;
					}
				});  
				this.cuotas.forEach(element => {
					if(this.poliza.cuotas==element.code){
						this.poliza.cuotas = element;
					}
				});
				this.Actualizacion1();
				this.Calculo();
                this.$store.commit('Loading');
            }); 
		},
        Actualizacion1() {
            this.poliza.evaluacion.valorcatalogo = this.poliza.veh_ano.valor;
            this.poliza.evaluacion.limite = this.poliza.veh_ano.valor*this.poliza.factorlimite.limite;
            //this.poliza.evaluacion.valorcatalogo = this.poliza.evaluacion.tipopoliza.valor;
            //this.poliza.evaluacion.limite = this.poliza.evaluacion.tipopoliza.valor*this.poliza.factorlimite.limite;
			this.Actualizacion2();
        },
		Actualizacion2() {
			if (this.poliza.evaluacion.valorcatalogo>0 && this.poliza.evaluacion.poliza_valor!=null){
				this.$store.commit('Loading');
				const Consulta = new API('Evaluacion');
				Consulta.Procesar('Vehiculo',{
					caso: 	'Actualizacion2',
					poliza_valor: this.poliza.evaluacion.poliza_valor.code,
					valorcatalogo: this.poliza.evaluacion.valorcatalogo,
					tipocliente: this.poliza.tipocliente,
					tipopoliza: this.poliza.evaluacion.tipopoliza.code,
				}).then(response => {
					//this.$store.state.error = response;
					this.poliza.evaluacion.limite = response.result.limite;
					this.poliza.evaluacion.primabruta = response.result.primabruta;
					this.Calculo();
					this.$store.commit('Loading');
				}); 
			}
		},
        Ver(data){
            const doc = new PDF();
			var monto = ((data.evaluacion.primabrutat*this.duracion[data.duracion].valor*(1+this.cuotas[data.cuotas].porcentaje/100)+parseFloat(data.evaluacion.kosten))*(1+parseFloat(data.evaluacion.impuesto)/100));
            doc.Cotizacion(2,data,this.duracion,this.cuotas,monto);
        },
		Calculo(){
			this.premie = 0;
			this.recarga_edadveh = 0;
            this.recarga_edad = 0;
			if (this.poliza.edadveh>7){
				this.recarga_edadveh = parseFloat(this.poliza.evaluacion.primabruta*this.poliza.evaluacion.limite*0.2).toFixed(2);
			}
			if (this.poliza.edad>=65){
				this.recarga_edad = parseFloat(this.poliza.evaluacion.primabruta*this.poliza.evaluacion.limite*0.2).toFixed(2);
			}
			if (this.poliza.veh_volante=="Right->"){
				this.recarga_volante = parseFloat(this.poliza.evaluacion.primabruta*this.poliza.evaluacion.limite*0.2).toFixed(2);
			} else {
				this.recarga_volante = 0;
			}
			if(this.poliza.evaluacion.tipopoliza.casco){
				this.premie = (parseFloat(this.poliza.evaluacion.primabruta)+parseFloat(this.poliza.evaluacion.valorcatalogo)*parseFloat(this.poliza.evaluacion.limite)+parseFloat(this.recarga_edadveh)+parseFloat(this.recarga_edad)+parseFloat(this.recarga_volante)).toFixed(2);
			} else {
				this.premie = (parseFloat(this.poliza.evaluacion.primabruta)*parseFloat(this.poliza.evaluacion.limite)+parseFloat(this.recarga_edadveh)+parseFloat(this.recarga_edad)+parseFloat(this.recarga_volante)).toFixed(2);
			}
			this.ob = (((parseFloat(this.premie*(1-this.poliza.evaluacion.noclaim.valor-this.poliza.evaluacion.descuento.valor)+parseFloat(this.poliza.evaluacion.adic_asientos))*this.poliza.duracion.valor*(1+this.poliza.cuotas.porcentaje/100))+parseFloat(this.poliza.evaluacion.kosten)+parseFloat(this.crs))*this.impuesto/100).toFixed(2);
		},	
		save() {
			if (this.poliza.evaluacion.tipopoliza == null) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: this.$t('Error en Tipo de Póliza'), life: 10000});
			} else if (this.poliza.evaluacion.valorcatalogo == 0) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: this.$t('Error en Valor Catálogo'), life: 10000});
			} else {
				this.Calculo();		
				//this.$store.commit('Loading');	
				const Consulta = new API('Evaluacion');          
				Consulta.Procesar('Vehiculo',{
					caso: 	'UPD',
					id: this.poliza.id,
					estado: this.poliza.estado.code,
					tipopoliza: this.poliza.evaluacion.tipopoliza.code,
					casco: this.poliza.evaluacion.tipopoliza.casco,
					valorcatalogo: this.poliza.evaluacion.valorcatalogo,
					poliza_valor: this.poliza.evaluacion.poliza_valor.code,
					primabruta: this.poliza.evaluacion.primabruta,
					comentario: this.poliza.evaluacion.comentario,
					limite: this.poliza.factorlimite.limite,
					recarga_edad: this.recarga_edad,
					recarga_veh: this.recarga_edadveh,
					recarga_volante: this.recarga_volante,
					kosten: this.poliza.evaluacion.kosten,
					crs: this.crs,
					ob: this.ob,
					noclaim: this.poliza.evaluacion.noclaim.code,
					descuento: this.poliza.evaluacion.descuento.code,
					duracion: this.poliza.duracion,
					cuotas: this.poliza.cuotas,
					procesar: this.procesar,
					renovacion: this.renovacion,
					usuario: this.poliza.usuario,
					veh_ano: this.poliza.veh_ano.code,
					exoneracion: this.poliza.exoneracion,
					leng: this.i18n.locale(),
					broker_monto: (((parseFloat(this.premie*(1-this.poliza.evaluacion.noclaim.valor-this.poliza.evaluacion.descuento.valor))+parseFloat(this.poliza.evaluacion.adic_asientos))*(1+this.poliza.cuotas.porcentaje/100)*this.poliza.duracion.valor)*this.poliza.evaluacion.broker/100),
				}).then(response => {
					//this.$store.state.error = response;
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: this.$t('Éxito'), 
							detail: this.$t('Información Procesada'), 
							life: 3000
						});
						//this.$store.commit('Loading');
						this.Mostrar();
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: this.$t('Advertencia'), 
							detail: this.$t('No fue procesada la solicitud'), 
							life: 3000
						});
						//this.$store.commit('Loading');
					}
					this.Dialog = false;
					this.poliza = null;
				});
			}
		},
		toggle(event) {
			this.$refs.op.toggle(event);
		},
		toggle2(event) {
			this.$refs.op2.toggle(event);
		},
		IMG(imegan){
			this.imagen = imegan;
			this.toggle1(event);
		},
		toggle1(event) {
			this.$refs.op1.toggle(event);
		},
        convfech(fecha){
            return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
		},
        moneda(number){
            return new Intl.NumberFormat("en", {style: "currency", currency: this.$store.state.currency}).format(parseFloat(number).toFixed(2));
		},


	},
	computed: {

    }

}
</script>
